<template>
    <header v-if="$page.props.header" class="py-10">
        <div class="flex items-center">
            <BackButton
                v-if="$page.props.header.back"
                :href="$page.props.header.back !== true ? $page.props.header.back : ''"
                @click.prevent="attemptHistoryBack"
                class="mr-2"
            />

            <h1 class="text-3xl font-bold text-pink-900">
                {{ $page.props.header.heading }}
            </h1>
        </div>
    </header>
</template>

<script setup>
import BackButton from '@/MemberApp/Components/BackButton.vue'
import { computed } from 'vue'
import { usePage } from '@inertiajs/vue3'

const back = computed(() => usePage().props.header?.back)

function attemptHistoryBack() {
    if (back.value !== true) {
        return
    }

    history.back()
}
</script>
